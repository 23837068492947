import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {DemoModule} from "@app/pages/demo/demo.module";
import {CredentialsGuard} from "@app/guards/credentials.guard";

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'demo',
        loadChildren: () => import('./pages/demo/demo.module').then(m => m.DemoModule),
      },
      {
        path: 'error',
        loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorModule)
      },
      {
        path: 'customer/:brand/:credentials',
        loadChildren: () => import('./pages/customer/customer.module').then(m => m.CustomerModule),
        canActivate: [CredentialsGuard]
      },
      {
        path: 'customer/:brand',
        loadChildren: () => import('./pages/customer/customer.module').then(m => m.CustomerModule),
        canActivate: [CredentialsGuard]
      }
      ,
      {
        path: 'lookup',
        loadChildren: () => import('./pages/lookup/lookup.module').then(m => m.LookupModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
